import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import { Link, graphql } from 'gatsby'
import Content, { HTMLContent } from '../components/Content'
import Img from 'gatsby-image'

export const ProductTemplate = ({
    content,
    contentComponent,
    description,
    title,
    image,
    price,
    link,
    helmet,
    tags,
    quantity,
    path,
    id
}) => {
  const PostContent = contentComponent || Content

  return (
    <Layout>
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
            <div className="column is-10 is-offset-1">
                <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
                </h1>
            </div>
        </div>
        <div className="columns">
            <div className="column is-5 is-offset-1">
                <Img
                fluid={image.childImageSharp.fluid}
                />
            </div>
            <div className="column is-5 ">
                {/* <p>{description}</p> */}
                {/* <p>{quantity} available</p> */}
                <p>${price}</p>
                <a className="button" href={link}>Buy on Etsy</a>
            </div>
        </div>
        <div className="columns">
            <div className="column is-10 is-offset-1">
                <PostContent content={content} />
                {tags && tags.length ? (
                    <div style={{ marginTop: `4rem` }}>
                        <h4>Tags</h4>
                        <ul className="taglist">
                        {tags.map(tag => (
                            <li key={tag + `tag`}>
                            <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                    ) : null}
            </div>
          </div>
        </div>
    </section>
      </Layout>
  )
}

ProductTemplate.propTypes = {
    content: PropTypes.string.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
    price: PropTypes.number,
    link: PropTypes.string,
    helmet: PropTypes.instanceOf(Helmet),
    tags: PropTypes.array,
    quantity: PropTypes.number,
    path: PropTypes.string,
    id: PropTypes.string
}

const Product = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <ProductTemplate
      content={post.html}
      contentComponent={HTMLContent}
      description={post.frontmatter.description}
      helmet={<Helmet title={`${post.frontmatter.title} | Product`} />}
      id={post.frontmatter.id}
      tags={post.frontmatter.tags}
      title={post.frontmatter.title}
      image={post.frontmatter.image}
      price={post.frontmatter.price}
      quantity={post.frontmatter.quantity}
      path={post.frontmatter.path}
      link={post.frontmatter.link}
    />
  )
}

Product.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Product

export const pageQuery = graphql`
  query ProductByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        image {
            childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        price
        link
        quantity
        id
      }
    }
  }
`
